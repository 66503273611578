import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import useStyles from './styles'
const InfoFooter = () => {
  const classes = useStyles()
  return (
      <Box className={classes.root}>
      <Container maxWidth="lg" sx={{pt:5,pb:0}}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography color="white" variant="h5">
            <a style={{textDecoration
:'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`/`}>{`${process.env.REACT_APP_WEBSITE_NAME}`}</a>    &copy; 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="subtitle1">
              {`${new Date().getFullYear()} | Pi Network  | Pi Converter  | Pi Fiat Currency | WEB 3.0 , ${new Date().getFullYear()}`}{/* <a style={{textDecoration
  :'none',color:'inherit'}}  target='_blank' rel='noreferrer' href={`https://minepi.com/developers/pi-hackathon`}>Pi Payment</a> */}
            </Typography>

          </Grid>
        </Grid>
      </Container>
    </Box>
   
  )
}

export default InfoFooter
