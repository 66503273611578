import React from 'react'
import PageTemplate from '../pagetemplate/PageTemplate'
import Form from './Form'

const FirebaseNotifaction = () => {

  return (
    <PageTemplate RightComponentOne={Form} />
  )
}

export default FirebaseNotifaction
