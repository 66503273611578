import React,{useEffect,useState} from 'react'
import { Grid,Card,Typography,CardMedia,useMediaQuery } from '@mui/material'
import {truncateString} from '../../../utils/Utils'
import { useHistory } from 'react-router-dom'
import useStyles from './styles'
import {myCld} from '../../../cloudinaryImage'
import  thumbnail from '../../../assets/icons/thumbnail.jpg'
import { remark } from 'remark'
import remarkHtml from 'remark-html'
const Post = ({post}) => {
  const classes = useStyles()
  const history =useHistory()
  const [imageobj]=useState(post.attributes.image)
  const [img,setImage]=useState('')
  const[markup,setMarkup]=useState()
  const isDesktop = useMediaQuery('(min-width:600px)');


  
  const handleonPostClick =()=>{
      history.push(`/blog/article/${post.attributes.title}`)
  }

  
  useEffect(()=>{

    async function markdownToHtml(markdown) {
      const result = await remark().use(remarkHtml).process(markdown)
      //console.log(result.value.toString())
      return result.value.toString()
    }   
    const trunc =truncateString(post.attributes.Excerpt,250)
    markdownToHtml(trunc).then((res)=>{
      setMarkup({ __html: res })
    })
    try {
    const imgid=imageobj.data.attributes.provider_metadata.public_id
    let img = myCld.image(imgid).format('auto').quality('auto').addFlag('lossy')
     
    setImage(img.data!==null ?img.toURL():thumbnail)
    } catch (error) {
      console.log(error)
    }
  },[imageobj, post.attributes.Excerpt, post.attributes.content])
  return  (
     <Card className={classes.root}  elevation={0} padding={1} onClick={()=>{handleonPostClick
     (post)}}>
          <Grid container justifyContent='space-between' padding={0.5} >

          <Grid item xs={2.5} border={0}>
            {post && img ? <CardMedia className={classes.image} image={`${img}`}  /> : ''}
           </Grid>
               
        <Grid item xs={9} border={0}>
        <Grid container justifyContent='flex-start' alignItems={'flex-start'}  flexDirection='column' spacing={0} >
        <Grid item xs={12} padding={0}>
        <Typography  variant='h6' fontSize={isDesktop ? '0.8rem': '1rem'} fontWeight={600} align='left' className={classes.title}>{truncateString(post.attributes.title,90)}</Typography>
        </Grid>
       {
        isDesktop ? <Grid item xs={12} justifyContent={'flex-start'} paddingRight={1} marginTop={-1.4}>
        {markup ? <Typography align={'left'} sx={{height:60,marginTop:0}} fontSize={'0.8rem'}  border={0} paddingTop={0} fontStyle={{color:'darkgray'}} className={classes.content}  dangerouslySetInnerHTML={markup}/> :''}
        </Grid>:''
       }
        {/* <Grid item xs={12}>
       <Grid container justifyContent={'space-between'}>
        <Grid item>
         <Typography className={classes.meta} variant='body2'>Author :  Abdul Razal</Typography>
        </Grid>
        <Grid item>
         <Typography className={classes.meta} variant='body2'>Date : 01/23/2023</Typography>
        </Grid>
        </Grid>
        </Grid> */}
       </Grid>
        </Grid>
        </Grid>
     </Card>
  )
}

export default Post
