export const  data = {
    "valid": true,
    "updated": 1657958408,
    "base": "USD",
    "rates": {
        "AED": 3.67329,
        "AFN": 88.03332,
        "ALL": 116.36232,
        "AMD": 413.4405,
        "ANG": 1.80292,
        "AOA": 431.9366,
        "ARS": 128.41452,
        "AUD": 1.47242,
        "AWG": 1.79759,
        "AZN": 1.7,
        "BAM": 1.94517,
        "BBD": 2.01993,
        "BCH": 0.009453582907922102,
        "BDT": 93.98738,
        "BGN": 1.94081,
        "BHD": 0.37749,
        "BIF": 2059.54395,
        "BMD": 1,
        "BND": 1.4031,
        "BOB": 6.87771,
        "BRL": 5.40807,
        "BSD": 1.00042,
        "BTC": 0.00004890647565753534,
        "BTG": 0.06476683937823835,
        "BWP": 12.80934,
        "BZD": 2.01655,
        "CAD": 1.30235,
        "CDF": 2002.6,
        "CHF": 0.97592,
        "CLP": 971.08,
        "CNH": 6.75849,
        "CNY": 6.7539,
        "COP": 4404.07,
        "CRC": 683.25596,
        "CUC": 1,
        "CUP": 25.75,
        "CVE": 109.66409,
        "CZK": 24.29212,
        "DASH": 0.023872045834328,
        "DJF": 177.729,
        "DKK": 7.3753,
        "DOP": 54.61174,
        "DZD": 147.36717,
        "EGP": 18.90715,
        "EOS": 1.053740779768177,
        "ETB": 52.21527,
        "ETH": 0.0007950073538180229,
        "EUR": 0.99143,
        "FJD": 2.23036,
        "GBP": 0.84357,
        "GEL": 2.94,
        "GHS": 8.12826,
        "GIP": 0.84357,
        "GMD": 54.0925,
        "GNF": 8677.9469,
        "GTQ": 7.74307,
        "GYD": 209.30307,
        "HKD": 7.8496,
        "HNL": 24.59425,
        "HRK": 7.44397,
        "HTG": 115.9238,
        "HUF": 396.99,
        "IDR": 14997.1,
        "ILS": 3.4913,
        "INR": 79.65,
        "IQD": 1460.174,
        "IRR": 42352.1,
        "ISK": 137.727,
        "JMD": 151.77519,
        "JOD": 0.70905,
        "JPY": 138.52,
        "KES": 118.256,
        "KGS": 80.97345,
        "KHR": 4086.87035,
        "KMF": 464.64825,
        "KRW": 1319.05,
        "KWD": 0.308,
        "KYD": 0.83375,
        "KZT": 482.24741,
        "LAK": 15039.492,
        "LBP": 1512.61165,
        "LKR": 360.16681,
        "LRD": 152.51,
        "LSL": 15.841,
        "LTC": 0.0192437217357837,
        "LYD": 4.87548,
        "MAD": 10.44287,
        "MDL": 19.32769,
        "MKD": 61.22015,
        "MMK": 1852.3566,
        "MOP": 8.0892,
        "MUR": 45.21318,
        "MVR": 15.351,
        "MWK": 1026.81435,
        "MXN": 20.5265,
        "MYR": 4.4492,
        "MZN": 63.833,
        "NAD": 15.841,
        "NGN": 415.42,
        "NIO": 35.86944,
        "NOK": 10.1467,
        "NPR": 127.77319,
        "NZD": 1.62152,
        "OMR": 0.38558,
        "PAB": 1.00042,
        "PEN": 3.90699,
        "PGK": 3.57057,
        "PHP": 56.293,
        "PKR": 210.84755,
        "PLN": 4.7337,
        "PYG": 6862.5951,
        "QAR": 3.6412,
        "RON": 4.89674,
        "RSD": 116.45743,
        "RUB": 56.375,
        "RWF": 1031.8656,
        "SAR": 3.75513,
        "SBD": 8.16038,
        "SCR": 13.16989,
        "SDG": 456.525,
        "SEK": 10.4674,
        "SGD": 1.39885,
        "SLL": 13170.65,
        "SOS": 584.53,
        "SRD": 22.50863,
        "SVC": 8.75403,
        "SZL": 17.18732,
        "THB": 36.61385,
        "TJS": 10.30414,
        "TMT": 3.51,
        "TND": 3.06366,
        "TOP": 2.36517,
        "TRY": 17.2434,
        "TTD": 6.79506,
        "TWD": 29.9255,
        "TZS": 2332.1,
        "UAH": 29.56022,
        "UGX": 3771.53255,
        "USD": 1,
        "UYU": 41.10675,
        "UZS": 10954.65,
        "VND": 23456.15,
        "XAF": 652.39512,
        "XAG": 0.053455925589351576,
        "XAU": 0.0005855743752653384,
        "XCD": 2.70269,
        "XLM": 9.487666034155598,
        "XOF": 652.38212,
        "XRP": 2.1715526601520088,
        "YER": 250.2625,
        "ZAR": 17.0435,
        "ZMW": 16.43156
    }
}

export const currencies ={
    "valid": true,
    "currencies": {
        "AED": "United Arab Emirates Dirham",
        "AFN": "Afghan Afghani",
        "ALL": "Albanian Lek",
        "AMD": "Armenian Dram",
        "ANG": "Netherlands Antillean Guilder",
        "AOA": "Angolan Kwanza",
        "ARS": "Argentine Peso",
        "AUD": "Australian Dollar",
        "AWG": "Aruban Florin",
        "AZN": "Azerbaijani Manat",
        "BAM": "Bosnia-Herzegovina Convertible Mark",
        "BBD": "Barbadian Dollar",
        "BCH": "Bitcoin Cash",
        "BDT": "Bangladeshi Taka",
        "BGN": "Bulgarian Lev",
        "BHD": "Bahraini Dinar",
        "BIF": "Burundian Franc",
        "BMD": "Bermudan Dollar",
        "BND": "Brunei Dollar",
        "BOB": "Bolivian Boliviano",
        "BRL": "Brazilian Real",
        "BSD": "Bahamian Dollar",
        "BTC": "Bitcoin",
        "BTG": "Bitcoin Gold",
        "BWP": "Botswanan Pula",
        "BZD": "Belize Dollar",
        "CAD": "Canadian Dollar",
        "CDF": "Congolese Franc",
        "CHF": "Swiss Franc",
        "CLP": "Chilean Peso",
        "CNH": "Chinese Yuan (offshore)",
        "CNY": "Chinese Yuan",
        "COP": "Colombian Peso",
        "CRC": "Costa Rican Colón",
        "CUC": "Cuban Convertible Peso",
        "CUP": "Cuban Peso",
        "CVE": "Cape Verdean Escudo",
        "CZK": "Czech Republic Koruna",
        "DASH": "DASH",
        "DJF": "Djiboutian Franc",
        "DKK": "Danish Krone",
        "DOP": "Dominican Peso",
        "DZD": "Algerian Dinar",
        "EGP": "Egyptian Pound",
        "EOS": "EOS",
        "ETB": "Ethiopian Birr",
        "ETH": "Ethereum",
        "EUR": "Euro",
        "FJD": "Fijian Dollar",
        "GBP": "British Pound Sterling",
        "GEL": "Georgian Lari",
        "GHS": "Ghanaian Cedi",
        "GIP": "Gibraltar Pound",
        "GMD": "Gambian Dalasi",
        "GNF": "Guinean Franc",
        "GTQ": "Guatemalan Quetzal",
        "GYD": "Guyanaese Dollar",
        "HKD": "Hong Kong Dollar",
        "HNL": "Honduran Lempira",
        "HRK": "Croatian Kuna",
        "HTG": "Haitian Gourde",
        "HUF": "Hungarian Forint",
        "IDR": "Indonesian Rupiah",
        "ILS": "Israeli New Sheqel",
        "INR": "Indian Rupee",
        "IQD": "Iraqi Dinar",
        "IRR": "Iranian Rial",
        "ISK": "Icelandic Króna",
        "JMD": "Jamaican Dollar",
        "JOD": "Jordanian Dinar",
        "JPY": "Japanese Yen",
        "KES": "Kenyan Shilling",
        "KGS": "Kyrgystani Som",
        "KHR": "Cambodian Riel",
        "KMF": "Comorian Franc",
        "KRW": "South Korean Won",
        "KWD": "Kuwaiti Dinar",
        "KYD": "Cayman Islands Dollar",
        "KZT": "Kazakhstani Tenge",
        "LAK": "Laotian Kip",
        "LBP": "Lebanese Pound",
        "LKR": "Sri Lankan Rupee",
        "LRD": "Liberian Dollar",
        "LSL": "Lesotho Loti",
        "LTC": "Litecoin",
        "LYD": "Libyan Dinar",
        "MAD": "Moroccan Dirham",
        "MDL": "Moldovan Leu",
        "MKD": "Macedonian Denar",
        "MMK": "Myanma Kyat",
        "MOP": "Macanese Pataca",
        "MUR": "Mauritian Rupee",
        "MVR": "Maldivian Rufiyaa",
        "MWK": "Malawian Kwacha",
        "MXN": "Mexican Peso",
        "MYR": "Malaysian Ringgit",
        "MZN": "Mozambican Metical",
        "NAD": "Namibian Dollar",
        "NGN": "Nigerian Naira",
        "NIO": "Nicaraguan Córdoba",
        "NOK": "Norwegian Krone",
        "NPR": "Nepalese Rupee",
        "NZD": "New Zealand Dollar",
        "OMR": "Omani Rial",
        "PAB": "Panamanian Balboa",
        "PEN": "Peruvian Nuevo Sol",
        "PGK": "Papua New Guinean Kina",
        "PHP": "Philippine Peso",
        "PKR": "Pakistani Rupee",
        "PLN": "Polish Zloty",
        "PYG": "Paraguayan Guarani",
        "QAR": "Qatari Rial",
        "RON": "Romanian Leu",
        "RSD": "Serbian Dinar",
        "RUB": "Russian Ruble",
        "RWF": "Rwandan Franc",
        "SAR": "Saudi Riyal",
        "SBD": "Solomon Islands Dollar",
        "SCR": "Seychellois Rupee",
        "SDG": "Sudanese Pound",
        "SEK": "Swedish Krona",
        "SGD": "Singapore Dollar",
        "SLL": "Sierra Leonean Leone",
        "SOS": "Somali Shilling",
        "SRD": "Surinamese Dollar",
        "SVC": "Salvadoran Colón",
        "SZL": "Swazi Lilangeni",
        "THB": "Thai Baht",
        "TJS": "Tajikistani Somoni",
        "TMT": "Turkmenistani Manat",
        "TND": "Tunisian Dinar",
        "TOP": "Tongan Paʻanga",
        "TRY": "Turkish Lira",
        "TTD": "Trinidad and Tobago Dollar",
        "TWD": "New Taiwan Dollar",
        "TZS": "Tanzanian Shilling",
        "UAH": "Ukrainian Hryvnia",
        "UGX": "Ugandan Shilling",
        "USD": "United States Dollar",
        "UYU": "Uruguayan Peso",
        "UZS": "Uzbekistan Som",
        "VND": "Vietnamese Dong",
        "XAF": "CFA Franc BEAC",
        "XAG": "Silver (troy ounce)",
        "XAU": "Gold (troy ounce)",
        "XCD": "East Caribbean Dollar",
        "XLM": "Stellar Lumens",
        "XOF": "CFA Franc BCEAO",
        "XRP": "Ripple",
        "YER": "Yemeni Rial",
        "ZAR": "South African Rand",
        "ZMW": "Zambian Kwacha"
    }
}
