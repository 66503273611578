import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    [theme.breakpoints.down('xs')]:{
          /* marginBottom:'10vh', */
         marginBottom:2,
         padding:'2px 3px',
         height:80,
        }
  },
  image: {
    width:'100%',height:'25vh',
    border:0,
    objectFit:'cover',
    [theme.breakpoints.down('xs')]:{
      height:'16vh',
      objectFit:'cover'
    }
    
  },
 
  title: {
    fontSize:'1.3rem',
    fontWeight:600,
    
  },
   meta:{
    fontSize:'0.9rem',
  },
  description:{
  fontSize:'0.7rem',
  },
  auhtor:{
    fontSize:8,
  }
}))