/* eslint-disable no-unused-vars */
import { Grid, Typography,Select,FormControl,InputLabel,MenuItem,TextField, Button, useMediaQuery} from '@mui/material';
import React,{useState,useEffect, useCallback} from 'react';
import './exchange-rate.css';
import useStyles from './styles'

const ExxchangeRate = ({ rates, /**/data,currencies,pGCV,setPiGCV,paramvalue,formarttoCurrency,resetFields}) => {

    /*const[pGCVinGHS,setPiGCVinGHS]=useState(pGCV*fiat);
    const[pGCVinNGN,setPiGCVinNGN]=useState(pGCV*fiat); */
    const classes= useStyles()
    const[fiat,setfiat]=useState(0);
    const[tmprates]=useState(data.rates);
    const[tmpcurrencies]=useState(currencies.currencies);
    const[calcPi,setCalcPi]=useState(false);
    const[calcFiat,setCalcFiat]=useState(false);
    const[productValue,setProductValue]=useState()
    const[piValue,setPiValue]=useState()
    const[paramiterval,setParamiterValue]=useState(paramvalue)
    const[fiatLabel,setFiatLabel]=useState('')
    const[restructobj,setRestrucBoj]=useState(false);
    const[results,setResults]=useState(0);
    const[piResults,setPiResults]=useState(0);
    const isDesktop = useMediaQuery('(min-width:600px)');

    //const[rates,setRates]=useState([]);

   
  const onLocalfiatInputChange =(e)=>{
      

      if (calcFiat){
       var productvalue= e.target.value;
      // console.log(productValue)
       setProductValue(productvalue);
       var res=convert(productvalue,fiat);
       setPiResults(res.toFixed(8))  
       document.getElementById('pi').value=res.toFixed(8)

      }
  }
  const convert =(productvalue,fiat)=>{
    return productvalue/fiat
  }
  const onPiInputChange =(e)=>{
   
   if (calcPi){
    var pivalue= e.target.value;
    setPiValue(pivalue);
    var res=convertPitoFiat(pivalue,fiat);
    setResults(res.toFixed(8));
    setProductValue(res.toFixed(8));
    document.getElementById('fiat').value=res.toFixed(8)
   }
}

const onPiInputSet =useCallback((value)=>{
   
  
  
   //setPiValue(value);
   var res=convertPitoFiat(value,fiat);
   setResults(res.toFixed(8));
   setProductValue(res.toFixed(8));
   document.getElementById('fiat').value=res.toFixed(8)
},[fiat])

  const convertPitoFiat =(pivalue,fiat)=>{
    return pivalue*fiat
  }
  const handleChange = (event) => {   
      setParamiterValue(null)
      setfiat(event.target.value);
      console.log(event.target.value);
      setFiatLabel('');

    
  
   if(productValue !== undefined){ 
    var res=convert(productValue,fiat);
    //setResults(res.toFixed(8))
    document.getElementById('fiat').value=productValue;
    document.getElementById('pi').value=res.toFixed(8)

  } 
  };
  const onfiatFocused = (e)=>{
   setCalcFiat(true); setCalcPi(false);  
   var str= document.getElementById('ex-currency-select-small').innerText
   console.log(str)
   setFiatLabel(str.slice(0,3));
   }
   const onpiFocused = ()=>{
    var str= document.getElementById('ex-currency-select-small').innerText
    setFiatLabel(str.slice(0,3));
   setCalcPi(true); setCalcFiat(false); 
   }

  const SelectComponent =({fiat,rates})=>{
    return (<FormControl sx={{ width: "100%",alignItems:'center',padding:0 }} lg={{width: 200}}size="small" >
    <InputLabel id="demo-select-small">Select Fiat currency</InputLabel>
    <Select className={classes.selectinput}
      labelId="currency-select-small"
      id="ex-currency-select-small" 
      size="small"
      margin ="dense"
      fullWidth
      type="number"
      value={fiat} 
      defaultValue={paramiterval ? paramiterval*pGCV :null}
      label="Select currency"
      onChange={(e)=>{handleChange(e)}}>
      <MenuItem value={fiat}>
        <em>None</em>
      </MenuItem>
      {
       rates!== undefined ? 
        rates.map((rate,index)=>(
         <MenuItem key={index} name={rate.name}  value={(rate.value)*pGCV}>{`${rate.name} ${rate.country } `}</MenuItem>
         
      )):'' 
    }
     {/*  <MenuItem value={415.33*pGCV}>NGN</MenuItem>
      <MenuItem value={414159}>USD</MenuItem> */}

    </Select>
  </FormControl>)
  }
   
  const onTextFieldChange = (e)=>{
    console.log(e.target.value);
    resetFields()
    if (e.target.value!==''){
      setPiGCV(parseInt(e.target.value))
    }else{
      setPiGCV(parseInt(0))
    }
  }
  const handleReset =()=>{
    setPiGCV(314159)
  }
  useEffect(()=>{

      if (paramiterval>=1){
        const values =rates.filter((rate)=>rate.name==='USD')
        if (values.length>0){
           console.log(values)
         setfiat(values[0].value*pGCV)
         document.getElementById('pi').value=1
         document.getElementById('fiat').value=values[0].value*pGCV
  
        }
      }
      
    // eslint-disable-next-line no-unused-vars
    const restructureObject=(tmprates,tmpcurrencies)=>{
      var arrRates = [];
    for(var property in tmprates){

     var obj={"name": [property],
              "value":tmprates[property],
              "country":tmpcurrencies[property]
             } 
     arrRates.push(obj)
  }    
    //console.log(arrRates);
    
  return arrRates
}

  if (!restructobj){
  //setRates(restructureObject(tmprates,tmpcurrencies));

  }  
  //console.log("rate : "+ JSON.stringify(rates))
return ()=>{
  setRestrucBoj(true)
}
  }, [restructobj, rates, tmprates, tmpcurrencies, pGCV, onPiInputSet, paramiterval])

  return (
 
       
        <Grid className='pi-exxchangerate'  container justifyContent='space-between' direction={'column'} border={0}  spacing={0} padding={2} /*  xs={12} sm={12} md={12} lg={12} */ >
        <Grid item >
        <Grid container justifyContent={'space-between'} >
                <Grid item xs={12} >
                  <Grid container justifyContent={'space-between'} direction='column' /* height='14vh' */ alignItems='flex-start'>
                  <Grid item >
                   <Grid container justifyContent='space-between' spacing={0}>
                      <Grid item >
                      <Typography variant="body1" gutterBottom={true} style={{textAlign:'left',padding:'2px ,3px'}}>Set Consensus Trading Value </Typography>
                      </Grid>

                      <Grid item>
                      {/*   <Clear/> */}
                      </Grid>
                   </Grid>
                </Grid>
                <Grid item >
                  <Grid container justifyContent={'space-between'} spacing={0} >
                    <Grid item xs={8}>
  
                    <TextField
                    className={classes.textinput}
                    label="Consensus Value"
                    id="outlined-gcv"
                    defaultValue="Small"
                    size="small"
                    value={pGCV}
                    onChange={(e)=>{onTextFieldChange(e)}}
                    InputLabelProps={{
                      shrink: true
                      
                    }}
                    />
                    </Grid>
                    <Grid item xs={4}>
                      <Button variant='text' onClick={()=>{handleReset()}}>Reset</Button>
                    </Grid>
                  </Grid>

                </Grid>
                  </Grid>
                </Grid>
                 { /*<Grid item justifyContent={'flex-start'}>
                 <Typography variant='body1' style={{textAlign:'left',padding:'5px ,5px'}} >Set Consensus Trading Value</Typography>
                </Grid> */}

             </Grid>
        </Grid>
       <Grid item xs={10} sm={8} md={8} lg={8}  marginTop={3}>
        <Grid container justifyContent={'space-between'}>
             <Grid item xs={6} sm={6} md={6} lg={6} >
           <SelectComponent rates={rates} fiat={fiat}/>
             </Grid>
             <Grid item xs={6} sm={6} md={6} lg={6} >
            <Grid container justifyContent={'space-between'} flexDirection='column' >
                    <Grid item >
                    <Typography variant='body2'>{`${fiatLabel} Value `}</Typography>
                     </Grid>
                     <Grid item >
                    <Typography variant='body2'>{`${formarttoCurrency(fiat)}  `}</Typography>
                     </Grid>
            </Grid>
             </Grid>
        </Grid>
    
                   
          </Grid>
            
       <Grid item >
       <Grid container  justifyContent='space-between' /*  direction={'column'} */ spacing={1}>
        <Grid item xs={6} sm={6} md={6} lg={6} /* className='ex-inputs' */>
        <TextField
          id="fiat"
          label={`${fiatLabel !=='' ?fiatLabel : 'fiat'}`}
          type="number"
          size="small"
          placeholder=''
          margin ="dense"
          fullWidth
          className={classes.textinput}
          onFocus={()=>{onfiatFocused()}}
          onChange={(e)=>{ onLocalfiatInputChange(e)}}
          InputLabelProps={{
            shrink: true
            
          }}
        /> 
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
        <TextField
          className={classes.textinput}
          id="pi"
          label="Pi "
          size="small"
          fullWidth
          margin ="dense"
          onFocus={onpiFocused}
          onChange={(e)=>{ onPiInputChange(e)}}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
         
        />
       </Grid>
       
    
   
        </Grid>
       </Grid>
           
         </Grid>
  )
}

export default ExxchangeRate
