import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
     root:{
        width:'100%',
        border:'0px solid ',marginBottom:0,position:'absolute',
         height: "20vh",
         backgroundColor: '#000',
         padding: 1,
         paddingBottom: 0,
         [theme.breakpoints.down('xs')]:{
            height:'20vh'
            /* marginBottom:'10vh', 
            display:'none'*/
           
      }
     },
    
     
}))