import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
   content:{
       padding:15,
       [theme.breakpoints.down('xs')]:{
        padding:4,
       }
      },
     left:{
     backgroundColor:'#fff',border:'0px solid',
      minHeight:'70vh',
      padding: 8,
      [theme.breakpoints.down('xs')]:{
       padding:0,
       minHeight:'50vh',
       overFlow:'scroll'
      }
     },
    
      right:{
         backgroundColor:'#fff',
          border:'0px solid',
          minHeight:'70vh',
          padding:15,
          [theme.breakpoints.down('xs')]:{
            padding:0,
            minHeight:'50vh',
            overFlow:'scroll'
            
          }
         },
         rightComponenttwoWrapper:{
       
            position: "sticky",
            top: "1rem",
            border:'0px solid',
           /*  minWidth: "275" */
     },
      exchangeWrapper:{
         padding:0,
         border:'1px solid darkgray',
         borderRadius:4,
         [theme.breakpoints.down('xs')]:{
          border:0,
         }
      }
    
     
}))