import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
   
      selectinput: {
        '& .MuiInputBase-root': {
          padding: '2px', //  input base
         
        },
        '& .MuiInputBase-input': {
          padding: '2px 4px', // input itself
         
        },
      },
      textinput: {
        '& .MuiInputBase-root': {
          padding: '2px', //  input base
        },
        '& .MuiInputBase-input': {
          padding: '2px 4px', // input itself
        },
      },
     
     
}))