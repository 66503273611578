import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    height:'120vh',
    overflow:'scroll',
    
    [theme.breakpoints.down('xs')]:{
          /* marginBottom:'10vh', 
         marginBottom:2,
         padding:'2px 3px',
         height:80,*/
        }
  },
 
 
}));
