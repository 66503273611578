import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
   root:{
       height:'12vh',
       [theme.breakpoints.down('xs')]:{
        
       }
      },
      toolbar:{
       paddingLeft:4,paddingRight:4,
       [theme.breakpoints.down('xs')]:{
        padding:0
       }
      },
    logo:{
        borderRadius:4,
        height:45,
        width:50,
        [theme.breakpoints.down('xs')]:{
            height:30,
            width:35,
           }
    }
     
     
}))