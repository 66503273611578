import React, { useState } from 'react';
import './style.css';
import axios from 'axios';

const Form = () => {
  // State to hold form input values
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [notificationColor, setNotificationColor] = useState('');
  const [token, setToken] = useState('cC-XPwbDScm_-5LT4jrk1b:APA91bFPfrvWDUHhF11xeePEkv3rsLpv7T_7oN9VjUg8gnmuYFMqNsPaRENcQKHKCMpRDFYVTW__lW65UHNKHuPva2J69e2vI54Zz8efB4ZioEL7mABa9G6zj2jT3rBJiiT_uvV_oDgE');

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the FCM payload
    const payload = {
      notification: {
        title: title,
        body: body,
        image: image
      },
      data: {
        notification_body: notificationBody,
        notification_android_color: notificationColor
      },
      token: token
    };

    const options = {
      priority: 'high'
    };

    try {
      // Send the payload to the server
      const response = await axios.post('http://localhost:3005/api/notification/sendToSpecific', { payload, options });
      console.log('Notification sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  return (
    <div className="form-container">
    <h2 className="form-title">Send FCM Notification</h2>
    <form onSubmit={handleSubmit} className="fcm-form">
      <div className="form-group">
        <label>Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="form-input"
          required
        />
      </div>

      <div className="form-group">
        <label>Body:</label>
        <input
          type="text"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          className="form-input"
          required
        />
      </div>

      <div className="form-group">
        <label>Image URL:</label>
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          className="form-input"
          required
        />
      </div>

      <div className="form-group">
        <label>Notification Body (Data):</label>
        <input
          type="text"
          value={notificationBody}
          onChange={(e) => setNotificationBody(e.target.value)}
          className="form-input"
          required
        />
      </div>

      <div className="form-group">
        <label>Notification Color (Data):</label>
        <input
          type="text"
          value={notificationColor}
          onChange={(e) => setNotificationColor(e.target.value)}
          className="form-input"
          required
        />
      </div>

     {/*  <div className="form-group">
        <label>Registration Token:</label>
        <input
          type="text"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          className="form-input"
          required
        />
      </div> */}

      <button type="submit" className="form-submit-btn">Send Notification</button>
    </form>
  </div>
  );
};

export default Form;
