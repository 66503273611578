import { Grid,Box } from '@mui/material'
import React from 'react'
import useStyles from '../../styles'
const PageTemplate = ({LeftComponent,RightComponentOne,RightComponentTwo}) => {
  const classes =useStyles()
  return (
    <Grid container className={classes.content} justifyContent={'space-between'}>
         <Grid item xs={0} lg={0.2}>

         </Grid>
         
         {LeftComponent ?  
         <Grid xs={12} lg={3.1}item className={classes.left} >
         <LeftComponent/>
        
        </Grid>:''}
      
        <Grid xs={12} lg={7.9} item className={classes.right}>
              <Grid container justifyContent={'space-between'} border={0}>
                 <Grid xs={12} lg={RightComponentTwo ? 7.9 :12}item >
                 {RightComponentOne?  <RightComponentOne/>:''}
                 </Grid>
              <Grid  item xs={12} lg={RightComponentTwo ? 4: 0} >
                <Box className={classes.rightComponenttwoWrapper}>
                   { RightComponentTwo ? <RightComponentTwo/> : ''}
                </Box>
             
              </Grid>
              </Grid>
        </Grid>
        <Grid item border={0} xs={0} lg={0.2}>

        </Grid>
       </Grid>
  )
}

export default PageTemplate
