import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    cursor:'pointer',
    height:100,
     [theme.breakpoints.down('xs')]:{
         padding:'2px 3px',
         height:82,
         border:'1px solid darkgrey'
        }
  },
  image: {
    width:120,height:90,
    [theme.breakpoints.down('xs')]:{
      width:80,height:75,
      borderRadius:6,
    }
  },
 
  title: {
    fontSize:'1rem',
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.6rem',
      fontWeight:500,
    }
    
  },
   meta:{
    fontSize:'0.9rem',
  },
  description:{
  fontSize:'0.7rem',
  },
 

 
}));
