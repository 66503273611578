import React, { useState } from 'react';
import {Button, Box } from '@mui/material';

const items = [
  'Item 1: Description of item 1',
  'Item 2: Description of item 2',
  'Item 3: Description of item 3',
  'Item 4: Description of item 4',
  'Item 5: Description of item 5'
];

function NavigationLayout({setPage,page,handlegetArtitles}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
   /*  if (currentIndex < items.length - 1) {
      //setCurrentIndex(currentIndex + 1);
      
    } */
    setCurrentIndex(currentIndex =>currentIndex + 1);
   
  /*   setPage(page =>page + 1)
    console.log(page) */
    handlegetArtitles(true,'+')
  };

  const handleBack = () => {
   /*  if (currentIndex > 0) {
      //setCurrentIndex(currentIndex - 1);
    } 
    setPage(page =>page - 1)
    console.log(page)*/
    handlegetArtitles(true,'-')
  };

  return (
   
      
        <Box p={2} display="flex" justifyContent="space-between">
          <Button
            variant="text"
            /* style={{color:'#fff'}} */
            color="primary"
            onClick={handleBack}
            disabled={page === 0}
          >
          {'  <<'}
          </Button>
          <Button
            
            variant="text"
            color="primary"
            onClick={handleNext}
            /* disabled={page === items.length - 1} */
          >
           {' >>'}
          </Button>
        </Box>
  );
}

export default NavigationLayout;
