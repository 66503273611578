
import React from 'react';
import './flyoutsidebar.css';
import {CloseOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {Switch,Grid,TextField} from '@mui/material';
import { orange } from '@mui/material/colors';

const MobileSlider = ({handleMouseDown,showMobileSidebar,setShowMobileSideBar,pGCV,setPiGCV,resetFields}) => {
  
  const onTextFieldChange = (e)=>{
    console.log(e.target.value);
    resetFields()
    if (e.target.value!==''){
      setPiGCV(parseInt(e.target.value))
    }else{
      setPiGCV(parseInt(0))
    }
  }
  const GreenSwitch  = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: orange[600],
      '&:hover': {
        backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: orange[600],
    },
  }));
    const label = { inputProps: { 'aria-label': 'Show Adds switch' } };
 const state=JSON.parse(localStorage.getItem('showAds'));
  const [switchState,setSwitchState]=React.useState(state!==null ?state: true );

  const handleSwitchStateChange=(e)=>{
    localStorage.setItem('showAds',e.target.checked);
    setSwitchState(e.target.checked);
  }
  const handleReset =()=>{
    setPiGCV(314159)
  }
  return (
    <div id='flyoutMenu' className={showMobileSidebar ? 'show' : 'hide'}  /* onMouseDown={handleMouseDown} */>
      <Grid container justifyContent='flex-end'>
      <Grid item xs={2} >
          <CloseOutlined onClick={()=>{setShowMobileSideBar(!showMobileSidebar)}}/>
          </Grid>
      </Grid>
      <Grid container  justifyContent={'space-between'}>
          <Grid item xs={12} padding={1} style={{border:'0px solid'/**/ ,height:'84vh' ,overflow:'scroll'}} marginTop={'2vh'} justifyContent='space-around' direction='column' >

          <Grid container direction="column" style={{border:'0px solid'/* ,height:'82vh' */,}} justifyContent="space-between" alignItems="center">
    
          <Grid item >
            <Grid container justifyContent={'space-between'} /* height='76vh' */  direction='column' >
                  <Grid item >
                  <Grid container justifyContent={'space-between'} >
                <Grid item xs={12} >
                  <Grid container justifyContent={'space-between'} direction='column' /* height='14vh' */ alignItems='flex-start'>
                  <Grid item >
                   <Grid container justifyContent='space-between' spacing={0}>
                      <Grid item >
                      <Typography variant="body1" gutterBottom={true} style={{textAlign:'left',padding:'2px ,3px'}}>Set Consensus Trading Value </Typography>
                      </Grid>

                      <Grid item>
                      {/*   <Clear/> */}
                      </Grid>
                   </Grid>
                </Grid>
                <Grid item >
                  <Grid container justifyContent={'space-between'} spacing={0} >
                    <Grid item xs={8}>
  
                    <TextField
              label="Consensus Value"
              id="outlined-gcv"
              defaultValue="Small"
              size="small"
              value={pGCV}
              onChange={(e)=>{onTextFieldChange(e)}}
              />
                    </Grid>
                    <Grid item xs={4}>
                      <Button variant='text' onClick={()=>{handleReset()}}>Reset</Button>
                    </Grid>
                  </Grid>

                </Grid>
                  </Grid>
                </Grid>
                 { /*<Grid item justifyContent={'flex-start'}>
                 <Typography variant='body1' style={{textAlign:'left',padding:'5px ,5px'}} >Set Consensus Trading Value</Typography>
                </Grid> */}

             </Grid>
                  </Grid>
               {/*  <Grid item >
                  <hr></hr>
                </Grid>
                  <Grid item >
                  <Grid container justifyContent={'space-between'} >
                <Grid item xs={12} >
                  <Grid container justifyContent={'space-between'}>
                  <Grid item xs={10}  >
                   <Typography variant="body1" style={{textAlign:'left',padding:'2px ,3px'}}>Show Ads </Typography>
                </Grid>
                <Grid item xs={2}>
                <Switch {...label} checked={switchState}
                  onChange={handleSwitchStateChange} color='secondary' />

                </Grid>
                  </Grid>
                </Grid>
                <Grid item justifyContent={'flex-start'}>
                 <Typography variant='body1' style={{textAlign:'left',padding:'5px ,5px',color:'darkgray'}} >Help cover server and maintenance cost</Typography>
                </Grid>

         </Grid>
                  </Grid> */}
            </Grid>
         

          
        
          </Grid>


         <Grid item >
            <Grid container style={{border:'0px solid',height:'auto'}} paddingTop={10}>
                <Grid item justifyContent={'center'}>
                <Typography variant='body1' color={'red'} >@AbdulRazakNaeate</Typography>  
                </Grid>
            </Grid>
         </Grid>
        
          </Grid>
          </Grid>
          
      </Grid>
             {/*  <div style={{position:'absolute',top:'20px',left:'80%'}}></div>

             <div className="content">
              
          </div> */}
    </div>
  )
} 


export default MobileSlider
