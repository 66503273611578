import React,{useEffect, useState} from 'react'
import { useParams} from 'react-router-dom'
import Article from '../article/Article'
import axios from 'axios'
import useStyles from './styles'
/* import strapi from 'strapi' */
import { Grid } from '@mui/material'
const Post = () => {
    const params = useParams();
    const [post,setPost]=useState()
    const [ispostloaded,setIspostLoaded]=useState(false)
    const classes =useStyles()
    useEffect(()=>{
   
      async function getArticleByTitle(title) {
        try {
          const response = await fetch(`${process.env.REACT_APP_STRAPI_HOST_API}/api/articles/by-title?title=${encodeURIComponent(title)}`);
          if (!response.ok) {
            throw new Error('Article not found');
          }
          const article = await response.json();
          setPost(article)
          console.log(article); // Returns the matching article
        } catch (error) {
          console.error('Error fetching the article:', error);
        }
      }
        const getArticle = async()=>{
         /*  const url=`https://naeatestudio-cms.herokuapp.com/api/articles/${params.id}?populate=*`
 */
          const url=`https://naeatestudio-cms.herokuapp.com/api/articles/${params.id}?populate=*`
         
          /* const entries = await strapi.entityService.findMany('api::article.article', {
            fields: ['title', 'content'],
            filters: { title: 'Pi is Money' },
            sort: { createdAt: 'DESC' },
            populate: { category: true },
          });
          console.log(entries) */
          return await axios.get(url)
       }
    
       const handlegetArticle = async()=>{
            getArticle().then((response)=>{
                  console.log(response.data)
                  setPost(response.data)
            })
       }  
       

        if (!ispostloaded){
           /*  console.log(params.id)
            const post =posts.filter(post => post.id===params.id)
            console.log(post)
            setPost(post[0]) */
            getArticleByTitle(params.title)

        }
        return ()=>{
         
            setIspostLoaded(true)
         /*  if (post){ } */
        }
    },[ispostloaded, params, params.id, post])
  return (
    <div className={classes.root}>
       <Grid container justifyContent={'space-between'} > 
        {/* <Grid item xs={12} sm={12} md={3} lg={3}>

        </Grid> */}
       <Grid item xs={12} sm={12} md={12} lg={12} >
        <Grid container >
        {post ?<>  <Article post={post} /></>:''}
        </Grid>

       </Grid>
      {/*  <Grid item xs={12} sm={12} md={3} lg={3}>

       </Grid> */}
    </Grid>
    </div>
   
  )
}

export default Post
