import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
   title:{
       display:'none',
       [theme.breakpoints.down('xs')]:{
        display:'block',
       }
      },  
      postswrapper:{
        border:'0px solid',
        overflow:'scroll',
        
      }
}))