import React,{useEffect,useState} from 'react'
import { Box, Grid, Typography} from '@mui/material';
import ExxchangeRates from '../exchangeRates/exxchangeRate/ExxchangeRate';
import GoogleAd from '../googleAdsesne/GoogleAd'
import {Adsense} from '@ctrl/react-adsense';
import {data,currencies} from '../../data.js';
import { remark } from 'remark';
import remarkHtml from 'remark-html';

const Home = ({rates,pGCV,setPiGCV,gotoPlayStore,formarttoCurrency,value,posts}) => {


   function randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
    
   
   const [isAssetsloaded,setIsAssetsLoaded]=useState(false)
   const [srcInt]=useState(randomIntFromInterval(0,6))
   const [post,setPost]=useState('')
   const [markup,setMarkup]=useState('')
   const[isArticleLoaded,setIsArticleLoaded]=useState(false)
 /*  const[pGCV,setPiGCV]=useState(314159);
  const[areRatesLoaded,setAreRatesLoaded]=useState(false);
  const[rates,setRates]=useState([]);
  const [showMobileSidebar,setShowMobileSideBar]=useState(false);
  const[fiat,setfiat]=useState(0);

  // eslint-disable-next-line no-unused-vars
  const[showAds,setShowAds]=useState(true);
 */
 
   useEffect(()=>{

      //https://naeatestudio-cms.herokuapp.com/api/articles/by-title?title=Pi%20Network%3A%20Revolutionizing%20Digital%20Currency%20with%20Pifiat%20Converter
      async function getArticleByTitle(title) {
         try {
           const response = await fetch(`https://naeatestudio-cms.herokuapp.com/api/articles/by-title?title=${title}`);
           if (!response.ok) {
             throw new Error('Article not found');
           }
           const article = await response.json();
           setPost(article)
           markdownToHtml(article.content).then((res)=>{
            setMarkup({ __html: res })
          })
            // Returns the matching article
         } catch (error) {
           console.error('Error fetching the article:', error);
         }
       }
       async function markdownToHtml(markdown) {
         const result = await remark().use(remarkHtml).process(markdown)
         //console.log(result.value.toString())
         return result.value.toString()
       }   
      
      const fetchImage = async () => {
         try {
            var images=[]
            var gamezImgs=[]
               for(let i=1;i<=1;i++){
                  const response = await import(`../../assets/atmequizicons/${`${i}.png`}`) 
                        images.push(response.default)
                const gamesres = await import(`../../assets/atmegamezicons/${`${i}.png`}`) 
                gamezImgs.push(gamesres.default)      
               }
            // setImages(images)
            /// setGamezImages(gamezImgs)
         } catch (err) {
             //setError(err)
         } finally {
             //setLoading(false)
         }
     }
      if(!isAssetsloaded){
         fetchImage()
      }
      if (!isArticleLoaded){
         getArticleByTitle('Pi%20Network:%20Revolutionizing%20Digital%20Currency%20with%20Pi%20Converter')
      }
      return  () => {
         setIsAssetsLoaded(true)
         setIsArticleLoaded(true)
      }
   })
   

  return (
      <div >
            <Grid container justifyContent={'space-between'} border='0px solid blue' padding={1}>
            <Grid item  xs={12} sm={12} md={12} lg={0} border={0}>

            </Grid>

            <Grid item  xs={12} sm={12} md={12} lg={12} border={0} /* style={{backgroundColor:'antiquewhite'}} */>
                <Grid container direction={'column'}>
                    
                     <Grid item xs={12} sm={12} md={12} lg={12}  >
                   {/*   <Adsense
                      client="ca-pub-3933719445212850"
                     slot="9077357511"
                     style={{ width: "95vw", height: 90 }}
                      format="
                     />
                     <Grid style={{width:'100%',border:'0px solid'}}>   
                     <Grid item xs={{display:'none'}} sm={{display:'none'}}  md={{display:'none'}}  lg={{display:'block'}} >
                         <GoogleAd slot="2852457680" googleAdId="ca-pub-3933719445212850"/> 

                        </Grid>         
                     </Grid>  */}
      
                     </Grid>
                      {/* <Grid item xs={10} sm={12} md={12} lg={12} marginTop={2} style={{border:'0px solid orange',borderRadius:'2px',marginTop:1}}>
                    <Typography style={{padding:'2px'}} variant='body2' color={'indianred'} > Desktop and Laptop with core i5,8GB RAM or high needed at  GCV price Ugently, if you can provide or know anyone who can please contact email below</Typography> 
                   <Typography variant="body2" > <a  href='mailto:naeatestudios@gmail. com'><EmailOutlined fontSize='small'className='topbarinfoIcon'/></a></Typography>

                      {   rates ? <ExxchangeRates value={value} rates={rates} data={data} currencies={currencies} pGCV={pGCV} setPiGCV={setPiGCV} formarttoCurrency={formarttoCurrency} />:''
                      }
                     </Grid>*/}

                   

                 
                 <Grid item  xs={12} sm={12} md={12} lg={{display:'none'}}>
           
                    {/*  <AtmeBanner src={images[0]} variant='quiz'link='https://3ba1dhl0elfs2nddh32fizeyaa.hop.clickbank.net'/>
            
       
         
                       <div style={{width:'80%',border:'0px solid'}}>  
                       <GoogleAd slot="7846312808" 
                       style={{display:'inline-block',width:'100%',height:'90px'}}
                         googleAdId="ca-pub-3933719445212850"/>
                       </div>*/}
      
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}  justifyContent='space-between'>  
                     <Typography variant='h5' fontWeight={600} color={'MenuText'}>{pGCV !==314159 ? 'Consensus Value' :'Pi Global Consensus Value'}</Typography>
                      <Typography variant='h5'  fontWeight={600} color={'red'} >{`$${formarttoCurrency(pGCV)}`}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} >

                      {markup ?<div style={{textAlign:'left',height:'100vh',overflow:'scroll'}}  dangerouslySetInnerHTML={markup} />:''}
                      {/*     <Typography style={{marinTop:'2vh'}} variant='body2' color={'GrayText'} >The app uses static  currency rates for now  to make the conversion as request  from currencyapi.net its exhausted,will be fixed soon </Typography> */}
                     
                     {/*  <Typography style={{marinTop:'0.5vh'}} variant='body1' color={'GrayText'} >Convert pi  currency to local currencies and convert local currency to pi  </Typography> */}
                    
                      <br/>
       


                        {/* <Typography style={{marinTop:'1vh'}} variant='body2' color={'GrayText'} > <a style={{textDecoration:'none'}} href='https://minepi.com/AbdulRazakNaeate'> click here to join if you are not a member</a> </Typography> 
                        <AtmeBanner src={gamezimages[srcInt]} variant='gamez' link='http://play444.atmegame.com/'/>*/}

                         {/*    <Typography style={{marinTop:'2vh'}} variant='body2' color={'GrayText'} >The app uses real time currency rates from currencypi.net to make the conversion </Typography> */}
                     
                        </Grid>

                        <Grid item sm={{display:'block'}} xs={{display:'block'}} md={{display:'none'}}lg={{display:'block'}}   border={0} height={90} >
                         <Grid container justifyContent={'center'}>
                              <Grid item xs={12}>
                                   <Adsense
                         client="ca-pub-3933719445212850"
                         slot="7791931357"
                        style={{display:'inline-block',width: "100%", height: 90 }}
                         format=""
                         responsive='true'
                        />
                         {/*<Adsense
                         client="ca-pub-3933719445212850"
                         slot="7846312808"
                        style={{display:'inline-block',width: 360, height: 90 }}
                         format=""
                         responsive='true'
                        />
                               <Adsense
                              client="ca-pub-3933719445212850"
                               slot="7846312808"
                               style={{ display: 'block' }}
                               layout="in-article"
                                format="fluid"
                              />
        
                           <Typography variant='body1' color={'red'} >@AbdulRazakNaeate</Typography>  
                     
                              <Typography variant="body2" > <a  href='mailto:naeatestudios@gmail.com'><EmailOutlined fontSize='small'className='topbarinfoIcon'/></a></Typography>*/}
                        
                              </Grid>
                           </Grid>    
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                     <Grid container  justifyContent='center'>
                     <Grid item >
                      {/*  <a href="googlechrome://play.google.com/store/apps/details?id=com.naeatestudio.pifiatconverter">link 1</a>
                       <a href="googlechrome://example.com">try it on Chrome</a> */}
                        <div style={{width:'100%',height:'auto',cursor:'pointer',border:'0px solid'}} onClick={()=>{gotoPlayStore()}}>
                       <img alt='Get it on Google Play' width={120}  src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                      </div>
                      </Grid>
                   </Grid>
                 </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={12} border={0} padding={5}>
     {/* Right 
         
         <Adsense
   client="ca-pub-3933719445212850"
   slot="7846312808"
   style={{ display: 'block' }}
   layout="in-article"
   format="fluid"
 />*/}
          {/*  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3933719445212850"
      crossorigin="anonymous"></script>
 <!-- pi fiat currency converter -->
 <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-3933719445212850"
      data-ad-slot="9077357511"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
 <script>
      (adsbygoogle = window.adsbygoogle || []).push({});
 </script> <div style={{width:'100%',border:'0px solid'}}>             
          <GoogleAd slot="2852457680" googleAdId="ca-pub-3933719445212850"/> 
            </div> */}

            </Grid>
      </Grid>
      </div>
  )
}

export default Home
