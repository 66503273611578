import React, { useEffect } from 'react';
import { Grid, Typography, Button,Box} from '@mui/material';
import { FaceOutlined,ShareOutlined,FavoriteOutlined} from '@mui/icons-material';
import useStyles from './styles'
import { useState } from 'react';
import Medialinks from '../medialinks/Medialinks';
import axios from 'axios';
import {myCld} from '../../cloudinaryImage'
import remarkHtml from 'remark-html'
import {remark} from 'remark'
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { Adsense } from '@ctrl/react-adsense';

const Article = ({post}) => {

  const[categories]=useState(post.categories)
  const[likes,setLikes]=useState(post.likes)
  const[userLikes,setUserLikes]=useState(false)
  const[markup,setMarkup]=useState()
  //console.log(post.data.attributes.likes)
  console.log(process.env.REACT_APP_SERVER_URL)
  const [imageobj]=useState(post.image)

 
  const [img,setImage] = useState('')
/*   console.log(post.data.attributes.categories.data)
 */  

      
    //const markup = { __html:  };
    //console.log(markup)

    const classes = useStyles()
   const onFavoriteClick =async ()=>{
    //console.log(post.data.id)
     var value=1;
       userLikes ? value=likes +1:value =likes -1
    updateLike(value).then((response)=>{
              console.log(response.data.data.attributes.likes)
             setLikes(response.data.data.attributes.likes)
             setUserLikes(true)
        })
   } 
   const updateLike = async(value)=>{
       
      
      const url=`${process.env.REACT_APP_STRAPI_HOST_API}/api/articles/${post.data.id}`
      return await axios.put(url,{data: {
        likes:value
    }})
   }
   useEffect(()=>{

    try {
      const imgid=imageobj.provider_metadata.public_id
     let img = myCld.image(imgid).format('auto').quality('auto').addFlag('lossy')
     let thumbn= thumbnail(100,80)
     setImage(img.data!==null ?img.toURL():thumbn(100,80))
    } catch (error) {
      
    }
    async function markdownToHtml(markdown) {
      const result = await remark().use(remarkHtml).process(markdown)
      //console.log(result.value.toString())
      return result.value.toString()
    }   
    markdownToHtml(post.content).then((res)=>{
      console.log(res)
      setMarkup({ __html: res })
    })
   },[imageobj, post.content]
   )
  return (

     <Grid item >
           <Grid container spacing={2} direction={'column'} padding={1} >
      <Grid item xs={12} >
          <Grid container justifyContent='space-between'  >
        
            <img className={classes.image} alt='article content' src={`${img}`} /> 
            </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">{post.title}</Typography>
      </Grid> 
      <Grid item sm={{display:'block'}} xs={{display:'block'}} md={{display:'block'}}lg={{display:'block'}}   border={0} height={90} >
                  
                 <Grid container justifyContent={'center'}>

                      <Grid item xs={12}>
                           <Adsense
                 client="ca-pub-3933719445212850"
                 slot="7791931357"
                style={{display:'inline-block',width: "100%", height: 90 }}
                 format=""
                 responsive='true'
                />
                 
                
                      </Grid>
                   </Grid>    
                </Grid>
      <Grid item xs={12}>
       <Box  style={{/* width:'400px', */overflow:"auto"}}>
       {markup ?<div style={{textAlign:'left'}}  dangerouslySetInnerHTML={markup} />:''}
      
       </Box>
      </Grid>
      <Grid item xs={12}>
       <Grid container>
        <Grid item xs={9}> 
        <Grid container justifyContent={'flex-start'} spacing={1}>
           {
                categories ? <>
                  { 
                  categories.map((category,i)=>{
                    return <Grid item  key={i}>
                    <Button variant="outlined" className='small-button' color="primary">
                    {category.title}
                  </Button>
                    </Grid>
                    })}
                </> :''
           }
       </Grid>
        </Grid>
       
       
       <Grid item xs={3} >
        <Grid container justifyContent={'flex-end'} spacing={1}>
              <Grid item> <Typography fontWeight={600} variant="body2"  color="textSecondary">
        <FavoriteOutlined color={userLikes ? 'primary' : 'textSecondary'} onClick={()=>{onFavoriteClick()}}/> 
        </Typography></Grid>


        <Grid item><Typography variant="body2"  color="textSecondary">
        { ` ${likes}` }
        </Typography>
        </Grid>
        </Grid>
      </Grid> 
       
       </Grid>
       </Grid>
     <Grid item xs={12} >
      
       <Grid container justifyContent={'space-between'}>

       {/* post.author ?
       <Grid item xs={8} >
        <Grid container justifyContent={'flex-start'}>
              <Grid item> <Typography fontWeight={600} variant="body2"  color="textSecondary">
        Author : 
        </Typography></Grid>


        <Grid item><Typography variant="body2"  color="textSecondary">
        { `${post.author}` }
        </Typography>
        </Grid>
        </Grid>
      </Grid>:'' */}
      
      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary" >
         {new Date(post.publishedAt).toDateString()}
        </Typography>
      </Grid>
      </Grid>
       </Grid>
       <Grid item>
         <Grid container justifyContent={'center'} spacing={2}>
         <Grid item>
         <Typography variant="body1" color="textSecondary" >
         Share
        </Typography>
                </Grid>
                <Grid item>
            <Medialinks/>
                </Grid>
                {/* <Grid item>
        <Button variant="contained" color="primary">
          Share
        </Button>
                </Grid> */}
         </Grid>
       </Grid>
     </Grid>
     </Grid>
  );
};

export default Article;